.disableBodyScroll{
    overflow: hidden;
}
.showOverflow{
    overflow: visible !important;
}
.smoothSlide {
	transition: transform .2s;
}
.container{
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #f6f6f6;
}
@mixin arrow {
    position: absolute;
    height: 20%;
    top: 40%;
    opacity: 0.4;
    // opacity: 0.18;
    transition: opacity 0.1s;
    z-index: 999;
}
@mixin arrowAfter{
    position: absolute;
    line-height: 20px;
    top: calc(50% - 10px);
    font-size: 35px;
    cursor: pointer;
}
.arrowNext{
    @include arrow;
    right: 5px;
}
.arrowPrev{
    @include arrow;
    left: 5px;
}
.arrowNext:after{
    @include arrowAfter;
    content: ">";
    // content: "▶";
    right: 0;
}
.arrowPrev:after{
    @include arrowAfter;
    content: "<";
    // content: "◀";
    left: 0;
}
.arrowNext:hover, .arrowPrev:hover{
    opacity: 0.5;
}
.slideContainer{
    top: 0;
    left: 0;
    position: absolute;
    z-index: 998;
    height: calc(100% - 70px);
    display: flex;
    touch-action: pan-y;
    will-change: transform;
    overflow: hidden;
    z-index: 998;
    box-shadow: -2px 0px 17px 4px rgba(182,182,182,0.28);
    cursor: zoom-in;
    // transition: 0.2s transform;
}
.zoomOut{
    cursor: zoom-out;
}
.slide{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
    will-change: transform;
    user-select: none;
    pointer-events: none;
    touch-action: none;
    img{
        position: absolute;
        max-height: 100%;
        max-width: 100%;
        // height: 100%;
        // width: 100%;
        // object-fit: contain;
        will-change: transform;
        user-select: none;
        pointer-events: none;
        touch-action: none;
        z-index: 999;
    }
}
.imgContainer{
    width: 100%;
    display: block;
}
.button_zoom{
    position: absolute;
    bottom: 0;
    left: 0;
}
.zoomedIn{
    transform: scale(2);
}
.dotContainer{
    max-width: 50%;
    width: 50%;
    height: 200%;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
}
.dotList{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style: none;
    position: absolute;
    top: 0;
    scroll-behavior: smooth;
    height: 30px;
}
.dotWrapper{
    position: relative;
}
.dot{
    flex: none;
    width: 15px;
    height: 15px;
    border: 2px solid rgb(0, 0, 0);
    // width: 20px;
    // height: 20px;
    // border: 3px solid rgb(95, 95, 95);
    border-radius: 999px;
    background: white;
    margin: 5px;
    cursor: pointer;
}
.dot_active{
    background: lime;
}